var prodcat = prodcat || {};
var site = site || {};

(function ($) {
  // When the menu changes, trigger skuSelect:
  $(document).on('change', 'select.js-sku-menu', function () {
    var skuId = $(this).val();
    var sku = prodcat.data.getSku(skuId);
    var skuBaseId;

    if (sku) {
      skuBaseId = sku.SKU_BASE_ID;
      $(this).closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
    }
    // Update Badge
    var $badgeNode = $('.product-full__flag');

    if ($badgeNode.length) {
      var badgeClassPrefix = 'product-full__flag--';
      var class_list = $badgeNode.attr('class').split(/\s+/);

      $(class_list).each(function () {
        var className = String(this);

        if (className.indexOf(badgeClassPrefix) !== -1) {
          $badgeNode.removeClass(className);
        }
      });
      if (sku.MISC_FLAG_TEXT) {
        $badgeNode.addClass(badgeClassPrefix + sku.MISC_FLAG_TEXT);
      }
    }
  });
  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    $('select.js-sku-menu', this).val(skuBaseId);
    $('select.js-sku-menu.selectBox-attached', this).selectBox('value', skuBaseId);
    var $product = $(this);
    // Price update logic
    var sku = prodcat.data.getSku(skuBaseId);

    if (sku) {
      var productDetails = prodcat.data.getProduct(sku.PRODUCT_ID);

      if (sku.LARGE_IMAGE[0]) {
        $product.find('.product-full__image-img').attr('src', sku.LARGE_IMAGE[0]);
      }
      if (productDetails.shaded === 1) {
        for (var i = 0; i < sku.LARGE_ALT_IMAGES.length; i++) {
          $product.find('.product-full__image-img').eq(i).attr('src', sku.LARGE_ALT_IMAGES[i]);
        }
      }
      // Need to wrap sku inside of defaultSku because thats where the template reads
      var content = site.template.get({
        name: 'product_sku_price',
        data: { defaultSku: sku }
      });

      $('.product-sku-price', this).html($(content).html());
    }
  });
})(jQuery);
